body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div.newsletter-success {
    color: rgb(14, 236, 19);
    font-weight: bold;
}

div.newsletter-error {
    color: rgb(255, 0, 48);
    font-weight: bold;
}

p.newsletter-note {
    font-size: 0.75rem;
    margin-top: 10px;
}
